.SignupForm_defaultUserFields__K5eH7 label{
    text-transform: uppercase;
}

.FieldTextInput_root__PUugK input{
    border-radius: 0;
    color: #003865;
}

#userType{
    border-radius: 0;
}

.Button_primaryButtonRoot__xQMAW{
    border-radius: 0;
    background-color: #5777A9 !important;
    color: #fff !important;
    text-transform: uppercase;
    letter-spacing: 1px;
}